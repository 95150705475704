<span #topAnchor></span>
<div #navCnt class="nav-cnt" *ngIf="forceDisplayNavigation || sections.length > 1">
    <div class="nav-main">
        <ul class="nav nav-pills">
            <li *ngFor="let section of sections" class="nav-item">
                <a class="nav-link no-line-break {{getNavLinkClass(section)}}"
                   (click)="jumpTo(section)"
                   [attr.id]="section.navLinkId"
                   tabindex="0">
                    {{section.label}}
                    <ng-container *ngIf="section.addOnTmp">
                        <ng-container *ngFor="let sectionAddon of section.addOnTmp">
                            <ng-container *ngTemplateOutlet="sectionAddon; context:{section: section}"></ng-container>
                        </ng-container>
                    </ng-container>
                </a>
            </li>
        </ul>
    </div>
    <ng-content select=".nav-add-on"></ng-content>
</div>

<ng-content></ng-content>

<div class="mt-3 d-flex nav-paging-btns" *ngIf="hasPagingButtons">
    <button class="btn btn-sm btn-outline-primary nav-prev-btn mr-3"
            *ngIf="getPrev() as prev"
            (click)="jumpTo(prev)">
        <fal i="chevron-left"></fal>
        {{prev.label}}
    </button>
    <button class="btn btn-sm btn-outline-primary nav-next-btn ml-auto"
            *ngIf="getNext() as next"
            (click)="jumpTo(next)">
        {{next.label}}
        <fal i="chevron-right"></fal>
    </button>
</div>
