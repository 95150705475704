import {Component} from '@angular/core';
import {getScrollParent} from '../../../utils/dom.utils';
import {fromEvent, merge, of} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NavigationComponent, NavigationSectionGroup} from './navigation.component';

@Component({
    selector: '[appNavigation=scroll]',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class ScrollNavigationComponent extends NavigationComponent {
    get scrollParent() {
        return getScrollParent(this.elRef.nativeElement);
    }

    get offset() {
        return this.navCntRef ? this.navCntRef.nativeElement.getBoundingClientRect().height : 0;
    }

    ngAfterViewInit() {
        merge(
            of(null),
            this.sections$,
            fromEvent(this.scrollParent, 'scroll'),
            fromEvent(window, 'resize'),
        ).pipe(takeUntil(this.destroy$)).subscribe(() => {
            const offset = this.offset;
            const sections = this.sections;
            const nextI = sections.findIndex(x => x.sections[0].elRef.nativeElement.getBoundingClientRect().y >= offset + 1);
            const i = nextI < 0 ? sections.length - 1 : Math.max(0, nextI - 1);

            if (this.activeSection !== sections[i]) {
                this.activeSection = sections[i];

                // TODO: this doesn't work
                const navEl: any = Array.from(this.navCntRef.nativeElement.querySelectorAll('.nav-item'))[i];
                navEl.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
            }
        });

        this.cdr.detectChanges();
    }

    jumpTo(section: NavigationSectionGroup) {
        const sp = this.scrollParent;
        sp.scroll({
            top: sp.scrollTop + section.sections[0].elRef.nativeElement.getBoundingClientRect().y - this.offset,
            behavior: 'smooth',
        });
    }
}
