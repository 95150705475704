import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Optional, TemplateRef} from '@angular/core';
import {NavigationComponent} from './navigation.component';
import {TabNavigationComponent} from './tab-navigation.component';
import {ScrollNavigationComponent} from './scroll-navigation.component';

@Directive({
    selector: '[appNavSection]',
    exportAs: 'navSection',
})
export class NavigationSectionDirective implements OnInit, OnChanges, OnDestroy {
    @Input() appNavSection: string;
    @Input() navLinkClass: string;
    @Input() addOnTmp: TemplateRef<any>;

    navLinkId: string;
    active = false;

    private _navigationComponent: NavigationComponent;

    get label() {
        return this.appNavSection;
    }

    constructor(public elRef: ElementRef,
        @Optional() tabNavigationComponent: TabNavigationComponent,
        @Optional() scrollNavigationComponent: ScrollNavigationComponent) {
        this._navigationComponent = tabNavigationComponent || scrollNavigationComponent;
    }

    ngOnChanges() {
        this.navLinkId = `appNavLink_${this.label.replace(/[^\w!?]/g, '')}`;
    }

    ngOnInit() {
        this._navigationComponent?.registerSection(this);
    }

    ngOnDestroy() {
        this._navigationComponent?.unregisterSection(this);
    }
}
